/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Container } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { Select, MenuItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";

import "../Assets/Style/Style.css";
import { StyledTableCell, StyledTableRow } from "../Assets/Style/Style.js";
import { getAllDeviceLogs, getDeviceLogsById } from "../api/ApiHelpers.js";
import { formatDate } from "../Utils/UtilityMethods.js";
import PaginationBar from "./PaginationBar.jsx";

function ListTable() {
  let { deviceId } = useParams();
  const [logs, setLogs] = useState([]);
  const [loader, SetLoader] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const fetchData = async (offset, rowsPerPage) => {
    SetLoader(true);
    let logsData = {};

    if (deviceId) {
      logsData = await getDeviceLogsById(deviceId, offset, rowsPerPage);
    } else {
      logsData = await getAllDeviceLogs(offset, rowsPerPage);
    }
    SetLoader(false);
    setLogs(logsData?.logs ?? []);
    setTotalCount(logsData?.totalCount ?? 0);
  };

  useEffect(() => {
    fetchData(page * rowsPerPage, rowsPerPage);
  }, [page, rowsPerPage]);

  const renderTableBody = () => {
    if (loader) {
      return (
        <StyledTableRow>
          <StyledTableCell
            colSpan={5}
            sx={{ textAlign: "center", height: "100vh" }}
          >
            <CircularProgress />
          </StyledTableCell>
        </StyledTableRow>
      );
    }
    if (logs?.length === 0) {
      return (
        <StyledTableRow>
          <StyledTableCell colSpan={5} sx={{ textAlign: "center" }}>
            No Logs Found
          </StyledTableCell>
        </StyledTableRow>
      );
    }
    return (
      <TableBody>
        {logs?.map((row, index) => (
          <StyledTableRow key={row.id}>
            <StyledTableCell component="th" scope="row">
              {page * rowsPerPage + (index + 1)}
            </StyledTableCell>
            <StyledTableCell>{row.device_id}</StyledTableCell>
            <StyledTableCell>
              <Box
                sx={{
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  backgroundColor: row.status ? green[500] : red[500],
                }}
              />
            </StyledTableCell>
            <StyledTableCell>{formatDate(row.createdAt)}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    );
  };

  return (
    <div className="main-table">
      <Container>
        <StyledTableCell>
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            displayEmpty
            inputProps={{ "aria-label": "Select Option" }}
          >
            <MenuItem value="" disabled>
              Select an Option
            </MenuItem>
            <MenuItem value="option1">Option 1</MenuItem>
            <MenuItem value="option2">Option 2</MenuItem>
            <MenuItem value="option3">Option 3</MenuItem>
          </Select>
        </StyledTableCell>
        {/* <StyledTableCell>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateRangePicker"]}>
              <DateRangePicker localeText={{ start: "From", end: "To" }} />
            </DemoContainer>
          </LocalizationProvider>
        </StyledTableCell> */}
        <StyledTableCell>
          <div className="head-btn">
            <Button variant="contained">Filter</Button>
          </div>
        </StyledTableCell>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>NO</StyledTableCell>
                <StyledTableCell>Device ID</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Date&Time</StyledTableCell>
              </TableRow>
            </TableHead>
            {renderTableBody()}
          </Table>
          <PaginationBar
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={totalCount}
          />
        </TableContainer>
      </Container>
    </div>
  );
}

export default ListTable;
