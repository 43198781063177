import * as apiConstants from "./ApiConstants";

export const getDevices = async () => {
  try {
    const res = await fetch(apiConstants.GET_DEVICES_API);
    const data = await res.json();
    if (data?.errorCode === 1) {
      return data;
    } else {
      throw Error("Error in fetching devices");
    }
  } catch (err) {
    console.error("error=========>", err);
    alert(err?.message);
  }
};

export const getDeviceStatus = async (requestPayload) => {
  try {
    const res = await fetch(apiConstants.GET_DEVICES_STATUS_API, {
      method: "POST",
      headers: apiConstants.headers,
      body: JSON.stringify(requestPayload),
    });
    const data = await res.json();
    if (data?.errorCode === 1) {
      return data?.spotbotStatus;
    } else {
      throw Error("Error in fetching status");
    }
  } catch (err) {
    console.error("error=========>", err);
    alert(err?.message);
  }
};

export const getAllDeviceLogs = async (offset, rowsPerPage) => {
  try {
    const res = await fetch(
      `${apiConstants.DEVICE_LOGS_BASE_URL}/api/parking/all?limit=${rowsPerPage}&offset=${offset}`
    );
    const resData = await res.json();
    if (resData?.message === "Devices retrieved") {
      return { logs: resData?.data, totalCount: resData?.totalCount };
    } else {
      throw Error("Error in fetching logs");
    }
  } catch (err) {
    console.error("error=========>", err);
  }
};

export const getDeviceLogsById = async (deviceId, offset, rowsPerPage) => {
  try {
    const res = await fetch(
      `${apiConstants.DEVICE_LOGS_BASE_URL}/api/parking/details/${deviceId}?limit=${rowsPerPage}&offset=${offset}`
    );
    const resData = await res.json();
    if (resData?.message === "Devices retrieved") {
      return { logs: resData?.data, totalCount: resData?.totalCount };
    } else {
      throw Error();
    }
  } catch (err) {
    console.error("error=========>", err);
  }
};

export const createDeviceLog = async (requestPayload) => {
  try {
    const res = await fetch(
      `${apiConstants.DEVICE_LOGS_BASE_URL}/api/parking/save`,
      {
        method: "POST",
        headers: apiConstants.headers,
        body: JSON.stringify(requestPayload),
      }
    );
    const resData = await res.json();
    if (resData?.message === "Device created") {
      return resData?.data;
    } else {
      throw Error();
    }
  } catch (err) {
    console.error("error=========>", err);
  }
};
