export const headers = {
  "Content-Type": "application/json",
};

export const GET_DEVICES_API =
  "https://13.234.177.173/spotbot/details?key=x724b95c2b3kz";

export const GET_DEVICES_STATUS_API =
  "https://spotbotenv-env.eba-cbzvevbw.ap-south-1.elasticbeanstalk.com/spotbot/status";

export const DEVICE_LOGS_BASE_URL = "https://cors-server-go8c.onrender.com/http://199.250.206.103:2001";
