export const getRandomStatus = () => Math.round(Math.random()).toString();

export const getDummyStatusData = () => [
  {
    nodeId: 226060817584184,
    status: getRandomStatus(),
  },
  {
    nodeId: 239998304007466,
    status: getRandomStatus(),
  },
  {
    nodeId: 253319614193440,
    status: getRandomStatus(),
  },
  {
    nodeId: 260060783690535,
    status: getRandomStatus(),
  },
  {
    nodeId: 278572495577731,
    status: getRandomStatus(),
  },
];

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
};
