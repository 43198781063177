import { createBrowserRouter } from "react-router-dom";
import TableComponent from "./Table/Table.jsx";
import ListTable from "./Table/ListTable.jsx";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <TableComponent />,
  },
  {
    path: "logs",
    element: <ListTable />,
  },
  {
    path: "logs/:deviceId",
    element: <ListTable />,
  },
]);
