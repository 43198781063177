/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Container } from "@mui/material";
import { green, red } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import "../Assets/Style/Style.css";
import {
  createDeviceLog,
  getDeviceStatus,
  getDevices,
} from "../api/ApiHelpers";
import { StyledTableCell, StyledTableRow } from "../Assets/Style/Style.js";
import { useNavigate } from "react-router-dom";
import { DeviceContext } from "../Contexts/DeviceContext.js";
import { getDummyStatusData } from "../Utils/UtilityMethods.js";

function TableComponent() {
  const navigate = useNavigate();
  const { devices, setDevices } = useContext(DeviceContext);
  const [loader, SetLoader] = useState(false);

  const getStatus = async (devices) => {
    const deviceIds = devices.map((device) => device.nodeId);

    const data = await getDeviceStatus({
      key: "x724b95c2b3kz",
      nodeIds: deviceIds,
    });
    return data;
  };

  const updateDeviceListWithStatus = async (deviceList, isFirst) => {
    // eslint-disable-next-line no-unused-vars
    const statusData = await getStatus(deviceList);
    const combinedArray = [];
    deviceList?.forEach((device) => {
      //Replace dummyStatusData with statusData
      getDummyStatusData()?.forEach((statusItem) => {
        if (device.nodeId === statusItem?.nodeId) {
          combinedArray.push({
            ...device,
            status: statusItem?.status,
          });
          if (!isFirst && device.status !== statusItem?.status) {
            //Call API to create new entry
            createDeviceLog({
              device_id: device.nodeId,
              status: statusItem?.status === "1" ? true : false,
            });
          }
        }
      });
    });
    setDevices(combinedArray);
  };

  const fetchData = async () => {
    SetLoader(true);
    const data = await getDevices();
    updateDeviceListWithStatus(data?.spotbot || [], true);
    SetLoader(false);
  };

  const handleSingleRefresh = async (device, index) => {
    // eslint-disable-next-line no-unused-vars
    const statusData = await getStatus([device]);
    //Replace dummyStatusData with statusData
    const dummyStatusData = [
      {
        nodeId: 278572495577731,
        status: device.status === "1" ? "0" : "1",
      },
    ];

    const updatedList = devices.map((deviceItem) =>
      deviceItem.nodeId === device.nodeId
        ? { ...deviceItem, status: dummyStatusData[0].status }
        : deviceItem
    );
    setDevices(updatedList);
  };

  const handleAllRefresh = async (devicesList) => {
    updateDeviceListWithStatus(devicesList ?? devices);
  };

  useEffect(() => {
    if (devices.length === 0) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    let intervalId;
    // Set a new interval and store its ID
    intervalId = setInterval(() => {
      handleAllRefresh(devices);
    }, 30000);

    // Cleanup function to clear the previous interval when devices change or component unmounts
    return () => clearInterval(intervalId);
  }, [devices]);

  if (loader) {
    return <CircularProgress sx={{ textAlign: "center" }} />;
  }

  return (
    <div className="main-table">
      <Container>
        <div className="head-btn">
          <Button variant="contained" onClick={() => handleAllRefresh(devices)}>
            Refresh All
          </Button>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell>Device ID</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Parking Location</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
                <StyledTableCell>Logs</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices.length === 0 ? (
                // <StyledTableRow>
                //   <StyledTableCell>No Devices found</StyledTableCell>
                // </StyledTableRow>
                <></>
              ) : (
                devices.map((row, index) => (
                  <StyledTableRow key={row?.nodeId}>
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell>{row?.nodeId}</StyledTableCell>
                    <StyledTableCell>
                      <Box
                        sx={{
                          height: 15,
                          width: 15,
                          borderRadius: "50%",
                          backgroundColor:
                            row?.status === "1" ? green[500] : red[500],
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>{row?.parkingLocation}</StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        aria-label="refresh"
                        onClick={() => handleSingleRefresh(row, index)}
                      >
                        <AutorenewIcon />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => navigate(`/logs/${row.nodeId}`)}
                      >
                        View
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="bottom-btn">
          <Button variant="contained" onClick={() => navigate(`/logs`)}>
            View Logs
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default TableComponent;
